import PropTypes from 'prop-types';

import classNames from 'classnames';

import Collapsible from '../Collapsible';

import styles from './FAQAccordionItem.module.scss';

const themes = {
  landlord: 'landlord',
  tenant: 'tenant'
};

const FAQAccordionItem = ({
  top,
  content,
  isOpened = false,
  theme = 'landlord',
  onCollapsedChange = () => {},
  isRedesign = false
}) => {
  const itemCN = classNames(styles['faq-accordion-item'], {
    [styles['faq-accordion-item--redesign']]: isRedesign
  });
  const topCN = classNames(
    styles['faq-accordion-item__top'],
    styles[`faq-accordion-item__top--${themes[theme]}`],
    {
      [styles[`faq-accordion-item__top--${themes[theme]}--redesign`]]:
        isRedesign
    }
  );

  const contentCN = classNames(styles['faq-accordion-item__content'], {
    [styles['faq-accordion-item__content--redesign']]: isRedesign
  });

  const CollapsibleIcons = isRedesign
    ? {
        iconClosed: (
          <span className={styles['faq-accordion-item__icon']}>+</span>
        ),
        iconOpened: (
          <span className={styles['faq-accordion-item__icon']}>-</span>
        )
      }
    : {};

  return (
    <div className={itemCN}>
      <Collapsible
        renderHeader={() => <h4 className={topCN}>{top}</h4>}
        renderBody={() => (
          <div
            className={contentCN}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        )}
        hasIcon
        isCollapsed={!isOpened}
        onCollapsedChange={onCollapsedChange}
        {...CollapsibleIcons}
      />
    </div>
  );
};

FAQAccordionItem.themes = themes;

FAQAccordionItem.propTypes = {
  top: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  isOpened: PropTypes.bool,
  theme: PropTypes.oneOf(Object.keys(themes)),
  onCollapsedChange: PropTypes.func,
  isRedesign: PropTypes.bool
};

export default FAQAccordionItem;
